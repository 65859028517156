@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    width: 100%;
    height: 100%;
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
        to bottom,
        transparent,
        rgb(var(--background-end-rgb))
      )
      rgb(var(--background-start-rgb));
  
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    
      /* For Webkit (Chrome, Safari, etc.) */
      &::-webkit-scrollbar {
        display: none;
      }
  }